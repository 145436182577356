<template>
	<div class="background-white w-100 mb-60 py-60 px-80 shadow-lg rounded">
		<h1 class="mb-10">Upplýsingar um terminalin</h1>

		<p class="mb-30 color-grey">Upplýsingar um terminal</p>

		<div class="mb-30">
			<label class="inline cursor-pointer">
				<div class="inline-block mb-10">Leiguterminalur</div>

				<br />

				<div class="switch" :class="{ active: form.lease }">
					<input type="checkbox" v-model="form.lease" />

					<div></div>
				</div>
			</label>
		</div>

		<div class="grid grid-2 grid-gap-20">
			<InputGroup v-model="form.verifoneNumber" id="verifoneNumber" label="Verifone Nummar" :required="true" />

			<InputGroup v-model="form.verifoneSerialnumber" id="verifoneSerialnumber" label="Verifone Seriu Nummar" />
		</div>

		<div class="grid grid-2 grid-gap-20">
			<InputGroup v-model="form.phoneNumber" label="Telefonnr." />

			<InputGroup v-model="form.psam" label="PSAM" />
		</div>

		<div class="grid grid-2 grid-gap-20">
			<InputGroup v-model="form.icc" label="ICC-nummar" />

			<InputGroup v-model="form.cradleNumber" label="Vøgga" />
		</div>

		<div>
			<InputGroup v-model="form.modelName" label="Model navn" />
		</div>

		<div>
			<label class="block mb-10" for="input-comment">Viðmerkingar</label>

			<textarea id="input-comment" v-model="form.comments"></textarea>
		</div>

		<div class="text-center color-red mt-15">
			{{ errorMessage }}
		</div>
	</div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import InputGroup from '@/components/InputGroup.vue';

export default {
	name: 'Terminal',

	props: {
		value: Object,
		errorMessage: String,
	},

	components: {
		InputGroup,
	},

	data() {
		return {
			form: {
				lease: false,
				verifoneSerialnumber: '',
				verifoneNumber: '',
				phoneNumber: '',
				psam: '',
				icc: '',
				cradleNumber: '',
				modelName: '',
				comments: '',
			},
			error: 'Lorem Test',
		};
	},

	mounted() {
		this.form.lease = !!this.value.lease;
		this.form.verifoneSerialnumber = this.value.verifoneSerialnumber;
		this.form.verifoneNumber = this.value.verifoneNumber;
		this.form.phoneNumber = this.value.phoneNumber;
		this.form.psam = this.value.psam;
		this.form.icc = this.value.icc;
		this.form.cradleNumber = this.value.cradleNumber;
		this.form.modelName = this.value.modelName;
		this.form.comments = this.value.comments;
	},

	methods: {
		hasError(inputField) {
			return this.$v.form[inputField].$invalid && this.$v.form[inputField].$dirty;
		},

		markAsDirty(inputField) {
			this.$v.form[inputField].$touch();
		},
	},

	validations: {
		form: {
			verifoneNumber: {
				required,
			},
		},
	},

	watch: {
		value: {
			handler(terminal) {
				this.form.lease = terminal.lease;
				this.form.verifoneSerialnumber = terminal.verifoneSerialnumber;
				this.form.verifoneNumber = terminal.verifoneNumber;
				this.form.phoneNumber = terminal.phoneNumber;
				this.form.psam = terminal.psam;
				this.form.icc = terminal.icc;
				this.form.cradleNumber = terminal.cradleNumber;
				this.form.modelName = terminal.modelName;
				this.form.comments = terminal.comments;
			},
		},

		form: {
			handler(value) {
				this.$emit('input', value);
			},

			immediate: true,

			deep: true,
		},
	},
};
</script>
