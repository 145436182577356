<template>
	<div>
		<template v-if="loading">
			<div class="flex justify-center">
				<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
			</div>
		</template>

		<div v-if="!loading" class="container-md">
			<terminal v-model="terminal" class="mb-50"></terminal>

			<div class="flex align-start">
				<a href="#" class="btn" @click.prevent="saveTerminal()">Rætta</a>
			</div>
		</div>
	</div>
</template>

<script>
import Terminal from '@/components/forms/Terminal.vue';
import axios from 'axios';

export default {
	name: 'TerminalEdit',

	components: {
		Terminal,
	},

	data() {
		return {
			loading: true,
			terminalId: null,
			terminal: {},
		};
	},

	mounted() {
		this.terminalId = this.$route.params.id;

		axios
			.get(`/terminals/${this.terminalId}`)
			.then((response) => {
				this.terminal = response.data;
			})
			.catch((error) => {
				console.log('Error fetching terminal', error);
			})
			.finally(() => {
				this.loading = false;
			});
	},

	methods: {
		saveTerminal() {
			axios
				.patch(`/terminals/${this.terminalId}`, this.terminal)
				.then(() => {
					this.$router.push({ name: 'Terminals' });
				})
				.catch((error) => {
					console.log('Error patching terminal', error);
				});
		},
	},
};
</script>
